<template>
  <div class="">
    <div class=""  name="Tooltip Button Style">
      <div
        v-b-toggle.tooltip-button-setting-collapse
        style="
          background: #f7f7f7;
          cursor: pointer;
          border-bottom: 1px solid #d2d8e0;
          border-top: 1px solid #d2d8e0;
        "
        class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center"
      >
        <div style="color: #18191c; font-weight: 500; font-size: 16px" class="">
          Button Setting
        </div>
        <div
          class="button-chevron"
          style="color: #b3afb6; font-weight: 500; font-size: 16px"
        >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
      <b-collapse
        accordion="button-accordion"
        id="tooltip-button-setting-collapse"
       
      >
      <div class="px-3">
        <div class="d-flex justify-content-between mt-2">
        <label for="button-text" class="editor-label"
          >Use Info Icon:</label
        >
        <b-form-checkbox
          :id="`add-a-mobile-image-${Date.now()}`"
          v-model="block.useIcon"
        ></b-form-checkbox>
      </div>
      <div class="">
        <div v-if="block.useIcon" class="d-flex mt-2 justify-content-between align-items-center">
        <span class="editor-label">Icon Variant:</span>
        <div class="select-per-page-count">
          <select
          class="m-0 p-0"
            aria-label="Default select example"
            v-model="selectedIcon"
          >
          <option v-for="(icon,index ) in block.Icons" :key="index" :value="icon" >{{icon}}</option>

          </select>
        </div>
      </div>
      <div v-else class="mt-2">
        <label for="button-text" class="editor-label">Tooltip Text:</label>
  <input type="text" v-model="block.text" placeholder="tooltip text" name="button-text" class="editor-input-control w-100">
      </div>
      </div>
      </div>
      <hr>
      <div class="px-3">
  <label for="button-text" class="editor-label">Button size:</label>
  <div class="d-flex gap">
<div class="d-flex align-items-center" title="Width (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;" >W</span>
  <input type="number" v-model="block.style.buttonWidth"  placeholder="Width" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center" title="Height (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;">H</span>
  <input type="number"  v-model="block.style.buttonHeight" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
  </div>
 </div>
 <hr>
 <div class="px-3">
  <FontFamily  v-model="block.style.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
  <input type="number" v-model="block.style.fontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
</div>
    <FontStyles :textBold.sync="block.style.fontWeight" 
  :textItalic.sync=" block.style.fontStyle"
  :textUnderLine.sync="block.style.textDecoration" class=""></FontStyles>
   
  </div>
<TextAlign v-model=block.style.textAlign ></TextAlign>
<label  for="button-text" class="editor-label">Button Position:</label>
    <div class="d-flex text-align-section">
      <label title="Start" for="left" @click="block.style.position='start'" :class="block.style.position=='start'?'active-editor-align':''" class="editor-text-left-control">
        <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM3 12H12V15H3V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
          <label title="Center" for="center"  @click="block.style.position='center'" :class="block.style.position=='center'?'active-editor-align':''"  class="editor-text-center-control">
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM6 12H16V15H6V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
          <label for="end" title="End" @click="block.style.position='end'" :class="block.style.position=='end'?'active-editor-align':''" class="editor-text-right-control">
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM10 12H19V15H10V12Z"
                  fill="#6D6B6D"
                />
              </svg>
          </label>
       
    </div>
 </div>
 <hr>
 <div class="px-3">
  <SelectColor v-model="block.style.backgroundColor" :opacity.sync="block.style.backgroundOpacity"  :label="'Button Color'"></SelectColor>
  <SelectColor v-model="block.style.color"  :opacity.sync="block.style.textOpacity" class="mt-3" :label="'Text Color'"></SelectColor>
 </div>
 <hr>
 <div class="px-3">
  <label for="" class="editor-label">Button stroke:</label>
  <Stroke  :borderSize.sync="block.style.borderSize"
  :borderRadius.sync="block.style.borderRadius"  ></Stroke>
 </div>
 <hr>
 <div class="pb-3 px-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.marginRight" :left.sync="block.style.marginLeft" :top.sync="block.style.marginTop" :bottom.sync="block.style.marginBottom"></padding>
  <padding  v-else :label="'Spacing (Mobile)'"  :right.sync="block.style.mobileMarginRight" :left.sync="block.style.mobileMarginLeft" :top.sync="block.style.mobileMarginTop" :bottom.sync="block.style.mobileMarginBottom"></padding> 
 </div>
      </b-collapse>
    </div>
    <div class=""  name="Tooltip Setting Style">
      <div
        v-b-toggle.tooltip-setting-collapse
        style="
          background: #f7f7f7;
          cursor: pointer;
          border-bottom: 1px solid #d2d8e0;
          border-top: 1px solid #d2d8e0;
        "
        class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center"
      >
        <div style="color: #18191c; font-weight: 500; font-size: 16px" class="">
          Tooltip Setting
        </div>
        <div
          class="button-chevron"
          style="color: #b3afb6; font-weight: 500; font-size: 16px"
        >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
      <b-collapse
        accordion="button-accordion"
        id="tooltip-setting-collapse"
       
      >
      <div class="px-3">
        
      <div class="">
      <div class="mt-2">
        <label for="button-text" class="editor-label">Tooltip Text:</label>
  <input type="text" v-model="block.toolTipContent" placeholder="Tooltip text" name="button-text" class="editor-input-control w-100">
      </div>
      </div>
      </div>
      <hr>
      <div class="px-3">
  <label for="button-text" class="editor-label">Button size:</label>
  <div class="d-flex gap">
<div class="d-flex align-items-center" title="Width (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;" >W</span>
  <input type="number" v-model="block.style.tooltipWidth"  placeholder="Width" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center" title="Height (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;">H</span>
  <input type="number"  v-model="block.style.tooltipHeight" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
  </div>
 </div>
 <hr>
 <div class="px-3">
  <FontFamily  v-model="block.style.tooltipFontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
  <input type="number" v-model="block.style.tooltipTextFontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
</div>
    <FontStyles :textBold.sync="block.style.tooltipFontWeight" 
  :textItalic.sync=" block.style.tooltipFontStyle"
  :textUnderLine.sync="block.style.tooltipTextDecoration" class=""></FontStyles>
   
  </div>
<TextAlign v-model=block.style.tooltipTextAlign ></TextAlign>
<label  for="button-text" class="editor-label">Button Position:</label>
    <div class="d-flex text-align-section">
      <label title="Start" for="left" @click="block.style.tooltipPosition='top'" :class="block.style.tooltipPosition=='top'?'active-editor-align':''" class="editor-text-left-control">
        <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM3 12H12V15H3V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
          
          <label for="end" title="End" @click="block.style.tooltipPosition='bottom'" :class="block.style.tooltipPosition=='bottom'?'active-editor-align':''" class="editor-text-right-control">
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM10 12H19V15H10V12Z"
                  fill="#6D6B6D"
                />
              </svg>
          </label>
       
    </div>
 </div>
 <hr>
 <div class="px-3">
  <SelectColor v-model="block.style.tooltipBg" :opacity.sync="block.style.tooltipBackgroundOpacity"  :label="'Button Color'"></SelectColor>
  <SelectColor v-model="block.style.tooltipTextColor"  :opacity.sync="block.style.tooltipTextOpacity" class="mt-3" :label="'Text Color'"></SelectColor>
 </div>
 <hr>
 <div class="px-3">
  <div class="d-flex mt-2 justify-content-between mt-2 align-items-center">
        <label for="" class="editor-label"> Border Radius: </label>
        <div class="select-per-page-count w-25 d-flex between">
          <div class="">
            <svg
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8333 15.8333H17.5V17.5H15.8333V15.8333ZM15.8333 14.1667H17.5V12.5H15.8333V14.1667ZM2.5 10.8333H4.16667V9.16667H2.5V10.8333ZM2.5 14.1667H4.16667V12.5H2.5V14.1667ZM2.5 7.5H4.16667V5.83333H2.5V7.5ZM2.5 4.16667H4.16667V2.5H2.5V4.16667ZM5.83333 4.16667H7.5V2.5H5.83333V4.16667ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM9.16667 17.5H10.8333V15.8333H9.16667V17.5ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM5.83333 17.5H7.5V15.8333H5.83333V17.5ZM2.5 17.5H4.16667V15.8333H2.5V17.5ZM17.5 6.66667C17.5 4.36667 15.6333 2.5 13.3333 2.5H9.16667V4.16667H13.3333C14.7083 4.16667 15.8333 5.29167 15.8333 6.66667V10.8333H17.5V6.66667Z"
                fill="#B3AFB6"
              />
            </svg>
          </div>
          <div class="">
            <input
              type="number"
              :min="0"
              class="w-100"
              v-model.number="block.style.tooltipTextBorderRadius"
              id=""
            />
          </div>
        </div>
      </div>

 </div>
 <hr>
 <div class="pb-3 px-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.tooltipPaddingRight" :left.sync="block.style.tooltipPaddingLeft" :top.sync="block.style.tooltipPaddingTop" :bottom.sync="block.style.tooltipPaddingBottom"></padding>
  <padding  v-else :label="'Spacing (Mobile)'"  :right.sync="block.style.tooltipMobilePaddingRight" :left.sync="block.style.tooltipMobilePaddingLeft" :top.sync="block.style.tooltipMobilePaddingTop" :bottom.sync="block.style.tooltipMobilePaddingBottom"></padding> 
 </div>
      </b-collapse>
    </div>
  </div>

</template>
<script>
import fontFamilyList from "../../../staticPages/fontFamilyList";
import FontFamily from "../MiniComponents/FontFamily.vue";
import FontStyles from "../MiniComponents/FontStyles.vue";
import Padding from "../MiniComponents/Padding.vue";
import SelectColor from "../MiniComponents/SelectColor.vue";
import Stroke from "../MiniComponents/Stroke.vue";
import TextAlign from "../MiniComponents/TextAlign.vue";

export default {
  components: {
    FontFamily,
    FontStyles,
    SelectColor,
    Stroke,
    TextAlign,
    Padding
  },
  props: {
    block: Object,
    index: Number,
     isDesktopView:Boolean,
  },
  data() {
    return {
      selectedIcon:this.block.selectedIcon,
      showBgOpacity:true,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }],
      ],
    };
  },
  methods:{
    checkHexCode(color){
      if(color){
        let pattern = /^#[0-9A-F]{6}$/i;
           return  color.match(pattern) ? true : false
      }
            
        },
    BgColorOutput(value){
      let isHex = this.checkHexCode(value)
      if(isHex){
        this.showBgOpacity = true
        return value
      } else{
        this.showBgOpacity = false
        return 'Gradient input'
      }
    },
  },
  computed:{
    getFontFamilyList() {
return fontFamilyList;
},
textBlockFont(){
      return this.block.style.fontFamily
    },
    toolTipFont(){
      return this.block.style.tooltipFontFamily
    },
  },
  watch:{
    textBlockFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
    toolTipFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
    selectedIcon(newVal){
      this.block.selectedIcon = newVal
    }
  }
};
</script>
<style >

 input[type="number"]::-webkit-outer-spin-button ,input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
}

</style>
